import React, {Component} from "react";
import {Spin} from "antd";
import axios from "axios";
import img from "../pages/images/No running meeting in the room.png"
import imgNotFound from "../pages/images/Room not found.png"

import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import NavBar from "./NavBar";
import {getUser} from "../Router";
import Iframe from 'react-iframe'
import {Button} from "reactstrap";


class Home extends Component {
    state = {
        isLoading: true,
        userData: {},
        message:"",
        message_code:"",
        url:""
    };

    async componentDidMount() {

        getUser().then(resp => this.setState({userData: resp.data}, function () {
             axios
                .get(process.env.REACT_APP_VLC_LAYER_BASE_URL+"/vts/recording/"+this.props.match.params.type +"/"+this.state.userData.name+'/'+this.props.match.params.name)
                .then((response) => {
                    console.log(response.data.recordings.recording.playback.format.url)

                    this.setState({message_code:response.data.returncode})
                    this.setState({url:response.data.recordings.recording.playback.format.url})
                    this.setState({isLoading:false})

                }).catch(error => {
                 if (error.response.data.status === "error") {
                     this.setState({message:error.response.data.message},()=>{
                         console.log(this.state.message)
                     })
                     this.setState({message_code:error.response.data.status_code})
                     this.setState({isLoading:false})
                 }
             });
        }))



    }

    render() {
        let {t}= this.props

        let rendered = this.state.isLoading ? (
            <div className="container cont">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="example">
                            <Spin size="large" style={{margin: "auto"}}/>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
                    <div className="container cont">
                        <div className="row">
                            {this.state.message_code === 90008 ?
                            <div className="col-md-12 text-center mt-5">
                                <img className="picnot" src={img} alt="not found"/>
                                <h1 className="not_found mt-4">
                            <span className="spanspin">

                                {t("no recording")}

                             </span>
                                </h1>
                                <a href={this.props.match.params.type === "student" ? process.env.REACT_APP_PARTICIPANT+"/vtsPaidSessions" :process.env.REACT_APP_INSTRUCTOR+"/vtsPendingSessions"}>
                                <Button className="notfoundbtn mt-3"
                                        >
                                    {t("back")}
                                </Button>
                                </a>
                            </div>:this.state.message_code === "SUCCESS" ?
                                    <div className="col-md-12 text-center mt-5">
                                        <Iframe url={this.state.url}
                                                display="initial"
                                                width="100%"
                                                height="700px"
                                                margin="auto"
                                                className="border-radius"
                                                allowfullscreen
                                        />
                                        <a href={this.props.match.params.type === "student" ? process.env.REACT_APP_PARTICIPANT+"/vtsPaidSessions" :process.env.REACT_APP_INSTRUCTOR+"/vtsPendingSessions"}>
                                        <Button className="notfoundbtn mt-3" >
                                            {t("back")}
                                        </Button>
</a>
                                    </div>:<div className="col-md-12 text-center mt-5">
                                    <img className="picnot" src={imgNotFound} alt="not found"/>
                                    <h1 className="not_found mt-4">
                            <span className="spanspin">

                                {t("not owned")}


                             </span>
                                    </h1>
                                        <a href={this.props.match.params.type === "student" ? process.env.REACT_APP_PARTICIPANT+"/vtsPaidSessions" :process.env.REACT_APP_INSTRUCTOR+"/vtsPendingSessions"}>
                                        <Button className="notfoundbtn mt-3"
                                               >
                                            {t("back")}
                                        </Button>
                                        </a>
                                    </div>}
                        </div>
                    </div>



        );
        return <div>
            <NavBar/>

            {rendered}
                  </div>;
    }
}

export default withRouter(withTranslation()(Home));
