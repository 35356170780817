import React from "react";
import "./App.css";
import {Route, Router, Switch} from "react-router-dom";

//pages
import Home from "./pages/Home.js";
import Vtube from "./pages/Vtube";
import {useKeycloak} from "@react-keycloak/web";
import {history} from "./history";
import {useTranslation} from "react-i18next";
import axios from "axios";
import keycloak from "./keycloak";
import Cookies from "universal-cookie";
import {ExamplePage} from "./pages/components/chatBot";
const cookies = new Cookies();
const guardAuth = (Component) => () => {

  return keycloak && !keycloak.authenticated ? (

    keycloak.login()
    ) : (
    <Component />
  );
};

export  function getUser()   {

  const params = {
    url: "profile/ar/get_user",
    data: {
      sso_sub: keycloak.tokenParsed.sub,
      student: keycloak.tokenParsed.email,
      name: keycloak.tokenParsed.given_name,
      last_name: keycloak.tokenParsed.family_name,
      student_mobile_number: keycloak.tokenParsed.phone_number ? keycloak.tokenParsed.phone_number : '',
    }
  }

  return  axios.post(process.env.REACT_APP_API_URL, params).then(res=>res.data)
}

const AppRouter = () => {
  const {i18n} = useTranslation();
  document.body.dir = i18n.dir();

  const {initialized} = useKeycloak();
  if (!initialized) {
    return true;
  }else{
    if (keycloak.authenticated) {

      if (cookies.get('locale_changed') == "true") {
        cookies.set('language sso', cookies.get('locale'))
        cookies.set('locale_changed', "false")
      } else {
        cookies.set('language sso', keycloak.tokenParsed.language != 'undefined' ? cookies.get('language sso') : 'ar')
      }
      let languageSettings = [{
        "locale": "ar",
        'direction': 'rtl',
        'lang': 'eg',
        'country': 'العربية'
      }, {"locale": "en", 'direction': 'ltr', 'lang': 'us', 'country': 'English'}];

      let locale = cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar';
      cookies.set('lang', languageSettings.find(x => x.locale === locale).lang)
      cookies.set('country', languageSettings.find(x => x.locale === locale).country)
      cookies.set('direction', languageSettings.find(x => x.locale === locale).direction)
      // cookies.set('locale',cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar')

    }
  }

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/:type/:name" component={guardAuth(Home)}/>
        <Route exact path="/Video/:type/:name" component={guardAuth(Vtube)}/>
        {/*<Route path="/Plans" component ={plans}/>*/}
      </Switch>
      {/* <ExamplePage/> */}

      {/*<Footer/>*/}
    </Router>
  );
};
export default AppRouter;
