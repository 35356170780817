import React, {Component} from "react";
import {Spin} from "antd";
import axios from "axios";
import img from "../pages/images/No running meeting in the room.png"
import imgNotFound from "../pages/images/Room not found.png"
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import NavBar from "./NavBar";
import {getUser} from "../Router";
import Iframe from 'react-iframe'
import {Button} from "reactstrap";


class Vtube extends Component {
    state = {
        isLoading: true,
        userData: {},
        message:"",
        message_code:"",
        url:"",
        status:""
    };

    async componentDidMount() {

        getUser().then(resp => this.setState({userData: resp.data}, function () {
             axios
                .get(process.env.REACT_APP_VLC_LAYER_BASE_URL+"/vts/recording_vtube/"+this.props.match.params.type +"/"+this.state.userData.name+'/'+this.props.match.params.name)
                .then((response) => {
                    this.setState({url:response.data.data.record_vtube})
                    this.setState({isLoading:false})
                    this.setState({message:response.data.message})
                    this.setState({message_code:response.data.status_code})
                    this.setState({status:response.data.status})
                }).catch(error => {
                 if (error.response.data.status === "error") {
                     this.setState({message:error.response.data.message},()=>{
                     })
                     this.setState({message_code:error.response.data.status_code})
                     this.setState({isLoading:false})
                 }
             });
        }))
    }

    render() {
        let {t}= this.props
        let rendered = this.state.isLoading ? (
            <div className="container cont">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="example">
                            <Spin size="large" style={{margin: "auto"}}/>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
                    <div className="container cont">
                        <div className="row">
                            {this.state.status === "success" ?
                                    <div className="col-md-12 text-center mt-5">
                                        <iframe id="myId2" title="VTS"width="100%" margin="auto" className="border-radius"
                                                height="700px" sandbox="allow-same-origin allow-scripts allow-popups"
                                                src={this.state.url + "?warningTitle=0&peertubeLink=0"} frameBorder="0"
                                                allowFullScreen></iframe>

                                        <a href={this.props.match.params.type === "student" ? process.env.REACT_APP_PARTICIPANT+"/vtsPaidSessions" :process.env.REACT_APP_INSTRUCTOR+"/vtsPendingSessions"}>
                                        <Button className="notfoundbtn mt-3" >
                                            {t("back")}
                                        </Button>
</a>
                                    </div>:<div className="col-md-12 text-center mt-5">
                                    <img className="picnot" src={imgNotFound} alt="not found"/>
                                    <h1 className="not_found mt-4">
                            <span className="spanspin">

                                {t("not owned")}


                             </span>
                                    </h1>
                                        <a href={this.props.match.params.type === "student" ? process.env.REACT_APP_PARTICIPANT+"/vtsPaidSessions" :process.env.REACT_APP_INSTRUCTOR+"/vtsPendingSessions"}>
                                        <Button className="notfoundbtn mt-3"
                                               >
                                            {t("back")}
                                        </Button>
                                        </a>
                                    </div>}
                        </div>
                    </div>



        );
        return <div>
            <NavBar/>

            {rendered}
                  </div>;
    }
}

export default withRouter(withTranslation()(Vtube));
