import Keycloak from 'keycloak-js'

const keycloakConfig = {
  url: process.env.REACT_APP_SSO,
  realm: 'Variiance',
  clientId: 'reactJs'

}
const keycloak = new Keycloak(keycloakConfig);
export default keycloak
