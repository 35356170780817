import React from "react";
import AppRouter from "./Router";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import keycloak from "./keycloak";
import "antd/dist/antd.css";
import {ToastContainer} from "react-toastify";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let dir = cookies.get("direction")
    ? cookies.get("direction")
    : "rtl";
if (dir === "ltr") {
  import("./pages/pages.css");
} else {
  import("./pages/pages.rtl.css");
}
const App = () => {
  return (
    <>
      <ReactKeycloakProvider authClient={keycloak}>
        <ToastContainer/>
        <div className="App">
          <AppRouter/>
        </div>
      </ReactKeycloakProvider>
    </>
  );
};

export default App;
